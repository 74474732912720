import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { environment } from '../../environments/environment';
import { UserForm } from '../models/user-form';

@Injectable({
  providedIn: 'root'
})
export class UserFormService {

  form!: FormGroup<UserForm>;

  constructor(private httpClient: HttpClient, private formBuilder: UntypedFormBuilder) {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      you: this.formBuilder.group({
        salutation: [''],
        firstName: ['', Validators.minLength(3)],
        lastName: ['', Validators.minLength(3)],
        personEmail: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        personBirthdate: [''],
      }),
      skin: this.formBuilder.group({
        selectedSkinType: ['', Validators.required],
        isSkinSensitive: [null, Validators.required],
        problematicTypology: ['', Validators.required],
      }),
      hair: this.formBuilder.group({
        selectedScalpType: ['', Validators.required],
        advices: this.formBuilder.array([], [Validators.required, Validators.maxLength(3)]),
      })
    })
  }

  selectMultiple(form: UntypedFormGroup, code: string, formControlName: string, selectedData: string[]) {
    const formArray = form.get(formControlName) as UntypedFormArray;

    if (selectedData.includes(code)) {
      const index = formArray.value.findIndex((index: string) => index === code);
      formArray.removeAt(index)
      return;
    }

    formArray.push(new UntypedFormControl(code));
  }

  sendUserData() {
    const data = {
      appCode: 'KLORANE',
      ...this.form.get('you')!.value,
      ...this.form.get('skin')!.value,
      ...this.form.get('hair')!.value,
    }

    function fixDate(d: Date): Date {
      d.setHours(d.getHours() - d.getTimezoneOffset() / 60);
      return d;
    }

    data.personBirthdate = fixDate(new Date(data.personBirthdate!));

    return this.httpClient.post(`${environment.apiUrl}/klorane/users`, data);
  }

}
