import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const slideAnimation = trigger('routeAnimations', [
  transition('* => UserFormPage', [
    query(':self', style({overflow: 'hidden'})),
    style({position: 'relative'}),
    query('.content', style({width: 0})),
    query('.user-form-content .row.gx-md-0 > div:last-child', style({transform: 'translateX(150%)'})),
    group([
      query('.content', animate('500ms cubic-bezier(0.22,0.61,0.36,1)', style({width: '50%'}))),
      query('.user-form-content .row.gx-md-0 > div:last-child', animate('500ms cubic-bezier(0.22,0.61,0.36,1)', style({transform: 'translateX(0)'}))),
    ]),
  ]),
  transition('UserFormPage => *', [
    query(':self', style({overflow: 'hidden'})),
    style({position: 'relative'}),
    query(':enter', style({display: 'none'})),
    query('.content', style({width: '50%'})),
    query('.user-form-content .row.gx-md-0 > div:last-child', style({transform: 'translateX(0)'})),
    group([
      query('.content', animate('500ms cubic-bezier(0.55,0.06,0.68,0.19)', style({width: 0}))),
      query('.user-form-content .row.gx-md-0 > div:last-child', animate('500ms cubic-bezier(0.55,0.06,0.68,0.19)', style({transform: 'translateX(150%)'}))),
    ])
  ]),
]);
