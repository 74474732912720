<div [@routeAnimations]="getRouteAnimationData()">
  <router-outlet></router-outlet>
</div>

<div class="navigation-indicator">
  <span [class.active]="url === '/'" routerLink="/"></span>
  <span [class.active]="url === '/formulaire' || url === '/formulaire/vous'"></span>
  <span [class.active]="url === '/formulaire/corps'"></span>
  <span [class.active]="url === '/merci'"></span>
</div>
