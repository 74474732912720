import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserFormGuard } from './user-form.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    data: {animation: 'HomePage'}
  },
  {
    path: 'formulaire',
    loadChildren: () => import('./user-form/user-form.module').then(m => m.UserFormModule),
    data: {animation: 'UserFormPage'},
    canActivateChild: [UserFormGuard]
  },
  {
    path: 'merci',
    loadChildren: () => import('./thanks/thanks.module').then(m => m.ThanksModule),
    data: {animation: 'ThanksPage'},
    canActivate: [UserFormGuard]
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
