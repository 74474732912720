import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ChildrenOutletContexts, NavigationStart, ParamMap, Router } from '@angular/router';
import { slideAnimation } from './animations';
import { Subscription } from 'rxjs';
import { UserFormService } from './services/user-form.service';
import { FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideAnimation]
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'Eaux thermales d’Avène qualification';
  url?: string;
  #innerWidth?: number;
  #activatedRouteSubscription?: Subscription;
  #routerSubscription?: Subscription;

  constructor(
    private contexts: ChildrenOutletContexts,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userFormService: UserFormService
  ) {
  }

  ngOnInit(): void {
    this.#innerWidth = window.innerWidth;

    const initialFormValue = localStorage.getItem('klorane-user-form');

    if (initialFormValue) {
      const initialFormValueData = JSON.parse(initialFormValue);
      this.userFormService.form.patchValue(initialFormValueData);

      const data = new Date(initialFormValueData.you.personBirthdate);
      this.userFormService.form.get('you')?.get('personBirthdate')?.setValue(data);

      const advices = this.userFormService.form.get('hair')!.get('advices') as FormArray;
      initialFormValueData.hair.advices?.forEach((index: string) => advices.push(new FormControl(index)));
    }

    this.#activatedRouteSubscription = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
      const email = params.get('email');
      const emailInput = this.userFormService.form.get('you')!.get('personEmail');

      if (email && emailInput) {
        emailInput.setValue(email);
        emailInput.markAsTouched();
      }
    });

    this.#routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.url = event.url;
      }
    });
  }

  getRouteAnimationData() {
    const animationData = this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];

    return this.#innerWidth && this.#innerWidth >= 768 ? animationData : `Mobile-${animationData}`;
  }

  ngOnDestroy(): void {
    this.#activatedRouteSubscription?.unsubscribe();
    this.#routerSubscription?.unsubscribe();
  }

}
